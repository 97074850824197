import React from "react"

const JsonTable = ({ data }) => {
  return (
    <table style={{ borderCollapse: "collapse" }}>
      <thead>
        <tr>
          <th style={{ border: "none" }}>Type</th>
          <th style={{ border: "none" }}>Name</th>
          <th style={{ border: "none" }}>State Mutability</th>
          <th style={{ border: "none" }}>Input Type</th>
          <th style={{ border: "none" }}>Input</th>
          <th style={{ border: "none" }}>Outputs</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.type}</td>
            <td>{item.name || "N/A"}</td>
            <td>{item.stateMutability || "N/A"}</td>
            <td>
              {item.inputs ? (
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {item.inputs.map((input, inputIndex) => (
                    <li key={inputIndex}>{input.type}</li>
                  ))}
                </ul>
              ) : (
                "N/A"
              )}
            </td>
            <td>
              <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                {item.inputs
                  ? item.inputs.map((input, inputIndex) => (
                      <li key={inputIndex}>
                        {input.name.startsWith(input.internalType + " - ")
                          ? input.name.slice(input.internalType.length + 3)
                          : input.name}
                      </li>
                    ))
                  : "N/A"}
              </ul>
            </td>
            <td>
              <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                {item.outputs
                  ? item.outputs.map((output, outputIndex) => (
                      <li key={outputIndex}>
                        {output.internalType} - {output.name} ({output.type})
                      </li>
                    ))
                  : "N/A"}
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default JsonTable
