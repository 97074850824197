import React, { useState, useEffect, useRef } from "react"

const OmnNav = ({ omnImage }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const items = [
    { image: "./omnlogo.gif", text: "go to omn.us", target: "https://omn.us" },
    { image: "docs.png", text: "docs", target: "https://omn.us" },
  ]

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick)
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick)
    }
  }, [items])

  const handleDocumentClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  const handleItemClick = (item) => {
    setIsOpen(false)

    if (item.target) {
      window.open(item.target, "_blank")
    }
  }

  console.log(isOpen)

  return (
    <div className="omn-nav">
      <div className="image-container" onClick={() => setIsOpen(!isOpen)}>
        <img src={omnImage} alt="omn.us" />
        {isOpen && (
          <div className="dropdown" ref={dropdownRef}>
            {items.map((item, index) => (
              <div key={index} onClick={() => handleItemClick(item)}>
                <img src={item.image} alt={item.text} />
                {item.text}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default OmnNav
